
<template>
    <div class="transportationPriceAdjustment" v-loading="loading">
        <currencyTable :table-data="tableData" ref="transportationPriceAdjustmentTable">
            <div slot="buttons" class="btns" v-if="isToConclude == false">
                <el-button size="mini" @click="addTasks">
                    新增
                </el-button>
                <el-button size="mini" @click="preservation">
                    保存
                </el-button>
                <el-button size="mini" @click="deleteTasks">
                    删除
                </el-button>
                <el-button size="mini" @click="dialogVisible = true">
                    生效时间设置
                </el-button>
            </div>
        </currencyTable>
        <el-dialog
            title="统一设置生效时间"
            width="26%"
            append-to-body
            :visible.sync="dialogVisible"
        >
            <el-date-picker
                type="datetime"
                v-model="setTime"
                placeholder="选择日期时间"
                style="width: 100% !important"
                value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="medium">取 消</el-button>
                <el-button type="primary" size="medium" @click="confirmSettingTime">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import currencyTable from '@/components/table/index.vue';
import util from '@/utils/util';

export default {
    // 运输调价列表
    name: 'transportation-price-adjustment',
    components: {
        currencyTable,
    },
    props: {
        vueFormParams: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            tableData: {
                isShowNo: false,
                generalQuery: false,
                showCheckBox: true,
                headerData: [{
                    title: '货源地名称',
                    field: 'source_name',
                    width: '120',
                }, {
                    title: '供应场站名称',
                    field: 'station_name',
                    width: '120',
                }, {
                    title: '供应商名称',
                    field: 'supplier_name',
                    width: '120',
                }, {
                    title: '物料分类名称',
                    field: 'type_name',
                    width: '150',
                }, {
                    title: '计量单位',
                    field: 'measure_unit',
                    width: '80',
                    editType: 'text',
                }, {
                    title: '运输方式',
                    field: 'transport_type',
                    width: '100',
                    state: [{
                        name: '散装',
                        val: 0,
                    }, {
                        name: '罐装',
                        val: 1,
                    }],
                }, {
                    title: '原运输单价(元)',
                    field: 'unit_price_before',
                    width: '120',
                }, {
                    title: '变更后运输单价(元)',
                    field: 'unit_price',
                    width: '160',
                    isEdit: true,
                    editType: 'text',
                }, {
                    title: '原运输税率',
                    field: 'tax_rate_before',
                    width: '120',
                }, {
                    title: '变更后运输税率',
                    field: 'tax_rate',
                    width: '160',
                    isEdit: true,
                    editType: 'text',
                }, {
                    title: '生效时间',
                    field: 'effective_date',
                    width: '200',
                    isEdit: true,
                    editType: 'item',
                }],
                requestUrl: `/Procurement/transport/price/adjust/details/${this.vueFormParams.businessInstanceId}`,
                requestParameter: '',
            },
            // 是否办结
            isToConclude: false,
            // 设置时间弹出层
            dialogVisible: false,
            // 时间对象
            setTime: '',
        };
    },
    computed: {},
    watch: {},
    created() {
        this.getCompletionStatus();
    },
    mounted() {},
    destroyed() {},
    methods: {
        // 获取办结状态
        getCompletionStatus() {
            this.$axios
                .get(`/interfaceApi/Procurement/material/price/adjust/get?businessinstanceid=${this.vueFormParams.businessInstanceId}`)
                .then(res => {
                    if (res && [2, 3].indexOf(res.approval_status) > -1) {
                        this.isToConclude = true;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 添加
        addTasks() {
            const queryString = `$frameId=${this.vueFormParams.formId}&businessInstanceId=${this.vueFormParams.businessInstanceId}`;
            this.$toast({
                title: true,
                text: '选择运输合同',
                type: 'eject',
                width: '13.4rpm',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'yshttjxzhtysx',
                        ViewCode: 'default',
                        QueryString: queryString,
                    },
                    selectCallback: this.selectRawMaterialCallback,
                },
            });
        },
        // 选择原材料回调
        selectRawMaterialCallback(data) {
            const tableData = this.$refs.transportationPriceAdjustmentTable.tableList;
            const newTableData = [];
            const newAddData = [];
            if (tableData.length > 0) {
                data.forEach(item => {
                    const row = tableData.filter(k => k.ctvid === item.ctvid);
                    if (row.length === 0) {
                        newTableData.push(item);
                    }
                });
            } else {
                newTableData.push(...data);
            }

            newTableData.forEach(item => {
                newAddData.push({
                    civid: item.civid,
                    ctcsid: item.ctcsid,
                    ctvid: item.ctvid,
                    source_id: item.source_id,
                    source_name: item.source_name,
                    station_id: item.station_id,
                    station_name: item.station_name,
                    supplier_id: item.supplier_id,
                    supplier_name: item.supplier_name,
                    type_name: item.type_name,
                    type_code: item.type_code,
                    type_dic_code: item.type_dic_code,
                    measure_unit: item.measure_unit,
                    transport_type: item.transport_type,
                    unit_price_before: item.transport_price,
                    unit_price: null,
                    tax_rate_before: item.transport_rate,
                    tax_rate: JSON.parse(JSON.stringify(item.transport_rate)),
                    effective_date: util.currentTimeFormat('yyyy-MM-dd HH:mm:ss'),
                    sort_num: item.sort_num,
                    rootid: item.rootid,
                });
            });
            this.$refs.transportationPriceAdjustmentTable.tableList.push(...newAddData);
        },
        // 保存
        preservation() {
            const row = this.$refs.transportationPriceAdjustmentTable.tableList;
            this.preservationRequest(row, '保存成功！');
        },
        // 保存请求
        preservationRequest(postData, text) {
            this.$axios
                .post(`/interfaceApi/Procurement/transport/price/adjust/details/save/${this.vueFormParams.businessInstanceId}`, postData)
                .then(() => {
                    this.$refs.transportationPriceAdjustmentTable.getTableData();
                    this.$message({
                        message: text,
                        type: 'success',
                    });
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 删除
        deleteTasks() {
            const data = this.$refs.transportationPriceAdjustmentTable.tableSelectData;
            if (!data) {
                this.$message('请选择需要删除的数据！');
            }
            let ids = '';
            data.forEach(element => {
                ids += element.id + ',';
            });

            this.$confirm('请确认操作', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.loading = true;
                    this.$axios
                        .get(`/interfaceApi/Procurement/transport/price/adjust/detail/delete/${ids}`)
                        .then(res => {
                            if (res) {
                                this.$refs.transportationPriceAdjustmentTable.getTableData();
                                this.$message({
                                    message: '删除成功！',
                                    type: 'success',
                                });
                            }
                            this.loading = false;
                        })
                        .catch(error => {
                            this.loading = false;
                            this.$message.error(
                                error.ErrorCode.Message
                            );
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消操作',
                    });
                });
        },
        // 确认时间
        confirmSettingTime() {
            const tableData = this.$refs.transportationPriceAdjustmentTable.tableList;
            tableData.forEach(item => {
                item.effective_date = this.setTime;
            });
            this.dialogVisible = false;
        },
    },
};
</script>
<style lang="stylus" scoped>
    .transportationPriceAdjustment
        height 100%
        >>> .currencyTable
            padding 0
            border 0
            .el-table
                .cell
                    padding 0
</style>
